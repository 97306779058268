import useSWR from "swr";
import {EdxApiTypes} from "@edx/api";
import {fetcher} from "../api/base";

export const useRegistries = () => {
  return useSWR<
  {
    data: ({
      data: EdxApiTypes.AssociationResponse[]
      error: any
      isLoading: boolean
      isValidating: boolean
  }
  & EdxApiTypes.AssociationResponse[])}>(`/api/AssociationItemList`, fetcher)
}
