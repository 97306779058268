import React from 'react'
import etalonLogo from '../assets/etalon-logo-color.png'
import { FaEnvelope, FaPhone } from 'react-icons/fa'
import { MdLogout } from 'react-icons/md'
import { useAuthState } from '../state/auth'
import { useCheckoutState } from '../state/checkout'
import { useNavigate } from 'react-router-dom'
import { clearSWRCache } from '../utils/storage'

interface HeaderProps {}

function Header({}: HeaderProps) {
  const authState = useAuthState()
  const checkoutState = useCheckoutState()
  const navigate = useNavigate()
  return (
    <header className="bg-gray-400/10 shadow flex  justify-between items-center p-6">
      <a href="https://etalondx.com" target="_blank">
        <img
          alt="Etalon's logo, which consists of a horse with DNA Helix along its body."
          className="mx-auto w-[230px]"
          src={etalonLogo}
        />
      </a>

      <div className="flex items-end space-x-3">
        <a
          href="mailto:info@etalondx.com"
          className="bg-edxred-500 rounded-full w-10 h-10 flex items-center
              justify-center text-white"
        >
          <FaEnvelope />
        </a>

        <a
          href="tel:+1-650-380-2995"
          className="bg-edxred-500 rounded-full w-10 h-10 flex items-center justify-center text-white"
        >
          <FaPhone />
        </a>

        {authState.isAuthenticated && (
          <div
            className="cursor-pointer bg-edxgrey-500/50 rounded-full w-10 h-10 flex items-center justify-center text-white"
            onClick={() => {
              authState.__reset()
              checkoutState.__reset()
              clearSWRCache()
              navigate('/login')
            }}
          >
            <MdLogout />
          </div>
        )}
      </div>
    </header>
  )
}

Header.defaultProps = {}

export { Header }
