import useSWR from 'swr'
import { fetcher } from '../api/base'
import { EdxApiTypes } from '@edx/api'

export const usePackageRules = () => {
  return useSWR<{
    data: ({
      addonId: number
      breedId: number
      id: number
      packageId: number
    } & EdxApiTypes.PackageRuleResponse)[]
  }>(`/api/PackageRuleItemList`, fetcher)
}
