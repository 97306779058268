import React, { useEffect } from 'react'
import { BrowserRouter, redirect, Route, Routes, useNavigate } from 'react-router-dom'
import { init } from '@fullstory/browser'
import TagManager from 'react-gtm-module'
import { datadogLogs } from '@datadog/browser-logs'
import { getEnvParams } from './config/environment'
import CreateUserForm from './screens/CreateUserForm'
import LoginForm from './screens/LoginForm'
import AddHorseForm from './screens/AddHorseForm'
import HorseSelection from './screens/HorseSelection'
import TestSelectionMobile from './screens/TestSelectionMobile'
import OrderReview from './screens/OrderReview'
import Checkout from './screens/Checkout'
import CheckoutSuccess from './screens/CheckoutSuccess'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { useFullStory } from './hooks/useFullStory'
import { useAuthState } from './state/auth'
import { usePrevious } from 'ahooks'
import { AuthService } from './services/auth'
import { useWindowSize, Toaster, Button, WebsiteCookiesNotice } from '@edx/react-common'
import { DesktopAppLayout } from './components/AppLayout/Desktop'
import { MobileAppLayout } from './components/AppLayout/Mobile'
import { TestSelectionDesktop } from './screens/TestSelectionDesktop'
import { DesktopRouteWrapper } from './components/AppLayout/DesktopRouteWrapper'
import { LocalStorageService } from './services/local-storage'
import GettingStartedSteps from './screens/GettingStartedSteps'
import { ErrorBoundary } from '@sentry/react'
import { useCheckoutState } from './state/checkout'
import { ErrorBoundaryFallback } from './components/ErrorBoundaryFallback'
import dayjs from 'dayjs'
import { clearSWRCache } from './utils/storage'

const { dataDogClientToken, dataDogTags, fullstoryOrgId, googleTagManagerId, paypalClientId } = getEnvParams()

if (googleTagManagerId) {
  const tagManagerArgs = {
    gtmId: googleTagManagerId,
  }

  TagManager.initialize(tagManagerArgs)
} else {
  console.error('Empty Google Tag Manager ID')
}

if (!dataDogClientToken) {
  console.error('Empty DataDog client token.')
}

const ddTags = {}

if (dataDogTags) {
  const properties = dataDogTags.split(',')

  properties.forEach(function (property) {
    const tup = property.split(':')
    ddTags[tup[0]] = tup[1]
  })
}

datadogLogs.init({
  clientToken: dataDogClientToken || 'empty',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  env: ddTags['env'],
  service: ddTags['service'],
})

datadogLogs.setGlobalContext(ddTags)

init({ orgId: fullstoryOrgId || '0000', cookieDomain: window.location.hostname })

const NegotiateIndexRoute = () => {
  const navigate = useNavigate()
  const authState = useAuthState()
  const searchParamString = window.location.search

  if (!authState.isAuthenticated) {
    const isEdxUser = LocalStorageService.get('is_edx_user')
    navigate(
        (isEdxUser ? '/login' : '/create-user') + searchParamString
    )
  } else {
    navigate('/test-selection' + searchParamString)
  }

  return null
}

const App = () => {
  useFullStory()
  const navigate = useNavigate()
  const authState = useAuthState()
  const checkoutState = useCheckoutState()

  const prevIsAuthenticated = usePrevious(authState.isAuthenticated)
  const searchParamString = window.location.search

  useEffect(() => {
    if (prevIsAuthenticated && !authState.isAuthenticated) {
      navigate('/login' + searchParamString)
    }

    if (authState.token) {
      const handleTokenValidation = () => {
        const isValid = AuthService.validateAccessToken(authState.token)

        if (!isValid) {
          authState.__reset()
          navigate('/login' + searchParamString)
        }
      }

      handleTokenValidation()

      // Validate the access token every 5 seconds to
      const interval = setInterval(handleTokenValidation, 5000)

      return () => clearInterval(interval)
    }
  }, [authState.isAuthenticated, prevIsAuthenticated])

  useEffect(() => {
    return AuthService.listenAuthExpiredEvent(() => {
      authState.__reset()
      navigate('/login' + searchParamString)
    })
  }, [])

  const { width, height } = useWindowSize()

  if (width > 1200) {
    return (
      <ErrorBoundary
        fallback={(props) => (
          <DesktopAppLayout>
            <ErrorBoundaryFallback {...props} />
          </DesktopAppLayout>
        )}
        onError={() => {
          authState.__reset()
          checkoutState.__reset()
          clearSWRCache()
        }}
      >
        <WebsiteCookiesNotice />
        <DesktopAppLayout>
          <Routes>
            <Route index Component={NegotiateIndexRoute} />

            <Route
              path="create-user"
              element={
                <DesktopRouteWrapper>
                  <CreateUserForm />
                </DesktopRouteWrapper>
              }
            />

            <Route
              path="start"
              element={
                <DesktopRouteWrapper>
                  <GettingStartedSteps />
                </DesktopRouteWrapper>
              }
            />

            <Route
              path="login"
              element={
                <DesktopRouteWrapper>
                  <LoginForm />
                </DesktopRouteWrapper>
              }
            />

            <Route
              path="horse-selection"
              element={
                <DesktopRouteWrapper>
                  <HorseSelection
                    onSelectHorse={(horse) => {
                      navigate('test-selection')
                    }}
                  />
                </DesktopRouteWrapper>
              }
            />
            <Route
              path="add-horse"
              element={
                <DesktopRouteWrapper>
                  <AddHorseForm />
                </DesktopRouteWrapper>
              }
            />

            <Route path="test-selection" element={<TestSelectionDesktop />} />

            <Route
              path="order-review"
              element={
                <DesktopRouteWrapper>
                  <OrderReview />
                </DesktopRouteWrapper>
              }
            />

            <Route
              path="checkout"
              element={
                <DesktopRouteWrapper>
                  <Checkout />
                </DesktopRouteWrapper>
              }
            />

            <Route
              path="checkout-success"
              element={
                <DesktopRouteWrapper>
                  <CheckoutSuccess />
                </DesktopRouteWrapper>
              }
            />

            <Route path="*" Component={NegotiateIndexRoute} />
          </Routes>
        </DesktopAppLayout>
      </ErrorBoundary>
    )
  }

  return (
    <ErrorBoundary
      fallback={(props) => (
        <MobileAppLayout>
          <ErrorBoundaryFallback {...props} />
        </MobileAppLayout>
      )}
      onError={() => {
        authState.__reset()
        checkoutState.__reset()
        clearSWRCache()
      }}
    >
      <MobileAppLayout>
        <Routes>
          <Route index Component={NegotiateIndexRoute} />

          <Route path="create-user" element={<CreateUserForm />} />

          <Route path="start" element={<GettingStartedSteps />} />

          <Route path="login" element={<LoginForm />} />

          <Route
            path="horse-selection"
            element={
              <HorseSelection
                onSelectHorse={(horse) => {
                  navigate('test-selection')
                }}
              />
            }
          />
          <Route path="add-horse" element={<AddHorseForm />} />

          <Route path="test-selection" element={<TestSelectionMobile />} />

          <Route path="order-review" element={<OrderReview />} />

          <Route path="checkout" element={<Checkout />} />

          <Route path="checkout-success" element={<CheckoutSuccess />} />

          <Route path="*" Component={NegotiateIndexRoute} />
        </Routes>
      </MobileAppLayout>
    </ErrorBoundary>
  )
}

export const AppEntry = () => {
  const buildDate = import.meta.env.VITE_CONFIG_BUILD_DATE
    ? dayjs(import.meta.env.VITE_CONFIG_BUILD_DATE).format('vMDHH')
    : ''
  return (
    <PayPalScriptProvider options={{ clientId: paypalClientId }}>
      <BrowserRouter>
        <div className="fixed top-0 left-0 text-gray-100 text-xs p-0.5">{buildDate}</div>
        <App />
        <Toaster />
      </BrowserRouter>
    </PayPalScriptProvider>
  )
}
