import useSWR from 'swr'
import { fetcher } from '../api/base'
import { useEffect } from 'react'
import { IHorse } from '../context/form-context'
import { useCheckoutState } from '../state/checkout'

export interface IHorsesDto {
  Filter?: string
}

export const useHorses = (
  params?: IHorsesDto,
): {
  data: { data: IHorse[] }
  error: any
  isLoading: boolean
  isValidating: boolean
} => {
  const { setAllHorses } = useCheckoutState()
  const { data, error, isLoading, isValidating } = useSWR(
    `/api/MainPage/Horses?Filter=${params?.Filter ?? ''}`,
    fetcher,
  )

  useEffect(() => {
    // we update all horses anytime the data changes
    if (data) {
      setAllHorses(data.data)
    }
  }, [data])

  return {
    data,
    error,
    isLoading,
    isValidating,
  }
}
