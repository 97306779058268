import React from 'react'
import ReactDOM from 'react-dom/client'
import { AppEntry } from './App'
import './index.css'
import * as Sentry from '@sentry/react'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    release: import.meta.env.VITE_CONFIG_BUILD_DATE,
    dsn: 'https://fdfa38204457ddaeba00af5f801bc039@o4506792561213440.ingest.us.sentry.io/4506859712479232',
    environment: process.env.NODE_ENV,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(<AppEntry />)
