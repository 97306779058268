import { Dispatch, SetStateAction } from "react";
import { IOrderHorse } from "../context/form-context";
import { mutate } from "swr";

export const getOrderHorsesFromStorage = (): IOrderHorse[] => {
	const orderHorsesState = sessionStorage.getItem('orderHorses');
	if (orderHorsesState) {
		const horses = JSON.parse(orderHorsesState);
		return horses;
	}
	return [];
};

export const getPaymentMethodFromStorage = () => {
	const paymentMethod = sessionStorage.getItem('paymentMethod');
	return paymentMethod;
};

export const getOrderIdFromStorage = () => {
	const orderId = sessionStorage.getItem('orderId');
	return orderId;
};

export const getAmountFromStorage = () => {
	const amount = sessionStorage.getItem('amount');
	return amount;
};

export const getCurrentHorseIdFromStorage = () => {
	const currentHorseId = sessionStorage.getItem('currentHorseId');
	return currentHorseId;
};

export const bindStorageState = (key: string, setState: Dispatch<SetStateAction<any>>) => {
	return (state) => {
		const data = (typeof state === 'object') ? JSON.stringify(state) : String(state);
		sessionStorage.setItem(key, data);
		setState(state);
	}
};

export const clearStorage = () => {
	sessionStorage.clear();
	localStorage.clear();
};

export const clearSWRCache = () => {
	mutate(() => true, undefined, { revalidate: false });
};