import { IOrderHorse, IPackage } from '../state/checkout'
import { useEffect, useState } from 'react'
import { usePackages } from './usePackages'
import { usePackageRules } from './usePackageRules'
import { uniqById } from '../utils/array'

export function useAvailableTestsForHorse(horse?: IOrderHorse) {
  const [tests, setTests] = useState<{
    addonTests: IPackage[]
    allTests: IPackage[]
    groups: IPackage['groups'][0][]
    recommendedTests: IPackage[]
  }>({
    addonTests: [],
    allTests: [],
    groups: [],
    recommendedTests: [],
  })

  const { data: packagesData, isLoading, error } = usePackages()
  const { data: packageRulesData, isLoading: isPackageRulesLoading } = usePackageRules()

  useEffect(() => {
    if (!horse) {
      setTests({ addonTests: [], allTests: [], groups: [], recommendedTests: [] })

      return
    }

    let addonTests: IPackage[] = []
    let allTests: IPackage[] = (packagesData?.data ?? []).sort(
      (test1, test2) => (test2?.priorityOrder ?? 0) - (test1?.priorityOrder ?? 0),
    )
    let recommendedTests: IPackage[] = []

    const breedIds = horse.breeds.map((breed) => breed.id)
    const rules = packageRulesData?.data || []
    const packages = packagesData?.data || []

    {
      const packageIds = horse?.packages?.map((pkg) => pkg.id) ?? []

      const result = rules
        .filter((rule) => breedIds.includes(rule.breedId) || rule.breedId === 1)
        .filter(
          (rule) =>
            packageIds.includes(rule.packageId) || (rule.packageId === 1 && !packageIds?.includes(rule.addonId)),
        )
        .map((rule) => packages.find((pkg) => pkg.id === rule.addonId))
        .filter((pkg) => !!pkg) as IPackage[]

      addonTests = uniqById(result).sort((test1, test2) => (test2?.priorityOrder ?? 0) - (test1?.priorityOrder ?? 0))
    }

    {
      const result = rules
        .filter((rule) => breedIds.includes(rule.breedId) || rule.breedId === 1)
        .map((rule) => packages.find((pkg) => pkg.id === rule.packageId))
        .filter((pkg) => !!pkg)
        .sort((test1, test2) => (test2?.priorityOrder ?? 0) - (test1?.priorityOrder ?? 0))
      recommendedTests = uniqById(result) as IPackage[]
    }

    const groups = allTests.reduce(
      (acc, test) => {
        const group = test.groups[0]
        if (group && !acc.some((g) => g?.id === group?.id)) {
          acc.push(group)
        }
        return acc
      },
      [] as IPackage['groups'][0][],
    )

    setTests({
      addonTests,
      allTests,
      groups,
      recommendedTests,
    })
  }, [horse?.id, packagesData, packageRulesData])

  return {
    addonTests: tests.addonTests,
    allTests: tests.allTests,
    categories: tests.groups,
    loading: isPackageRulesLoading || isLoading,
    recommendedTests: tests.recommendedTests,
  }
}
