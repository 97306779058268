import React, { useEffect, useState } from 'react'
import { Spinner } from 'flowbite-react'
import { useCheckoutState } from '../state/checkout'
import { useAvailableTestsForHorse } from '../hooks/useAvailableTestsForHorse'
import { HorsesListAside } from '../components/HorsesListAside'
import TestCard from '../components/TestCard'
import { cn, Input, Tabs, TabsList, TabsTrigger, useToast } from '@edx/react-common'
import { useHorses } from '../hooks/useHorses'
import { usePrevious } from 'ahooks'
import { TestTableList } from '../components/TestTableList'
import { FaTableList } from 'react-icons/fa6'
import { BsFillGrid3X2GapFill } from 'react-icons/bs'
import { FacetedFilter } from '../components/FacetFilter'
import { FaLayerGroup } from 'react-icons/fa'
import { OrderReviewAside } from '../components/OrderReviewAside'
import { HorsesComboBox } from '../components/HorsesComboBox'
import { useNavigate } from 'react-router-dom'
import { TestCardsList } from '../components/TestCardsList'

interface DesktopProps {}

function TestSelectionDesktop({}: DesktopProps) {
  const navigate = useNavigate()
  const [tab, setTab] = useState('recommended')
  const { allHorses, currentHorse, setCurrentHorse } = useCheckoutState()
  const {
    allTests,
    addonTests,
    categories,
    recommendedTests,
    loading: testsLoading,
  } = useAvailableTestsForHorse(currentHorse)
  // this is to trigger the fetch of data
  const { isLoading } = useHorses({ Filter: '' })
  const { toast } = useToast()
  const [testLayout, setTestLayout] = useState('table')
  const previousHorse = usePrevious(currentHorse)
  const [filterTests, setFilterTests] = useState('')
  const [facetFilter, setFacetFilter] = useState<string[]>([])

  useEffect(() => {
    if (!currentHorse && allHorses.length > 0) {
      setCurrentHorse(allHorses[0])
    }
  }, [allHorses, currentHorse])

  useEffect(() => {
    if (currentHorse?.id === previousHorse?.id) return

    if (currentHorse && previousHorse) {
      toast({
        title: 'Horse Changed',
        description: `Select tests for "${currentHorse.name}"`,
      })
    }
  }, [currentHorse])

  if (!allHorses.length && isLoading) {
    return (
      <div className="h-full w-full flex flex-col space-y-6 items-center justify-center">
        <Spinner className="fill-edxred-500" size="xl" />
        <p className="text-2xl">Loading Horses</p>
      </div>
    )
  }

  let tests: typeof allTests = []

  switch (tab) {
    case 'all':
      tests = allTests
      break
    case 'recommended':
      tests = recommendedTests
      break
    case 'addons':
      tests = addonTests
      break
  }

  if (filterTests || facetFilter.length > 0) {
    // use regex to filter tests on multiple words
    const filterWords = filterTests.split(' ')
    const regex = new RegExp(filterWords.join('|'), 'i')

    tests = tests.filter((test) => {
      return filterWords.some((word) => {
        return regex.test(test.name + ' ' + test.description)
      })
    })

    const hasCategoryFilter = !!facetFilter.find((facet) => facet.includes('category'))

    if (hasCategoryFilter) {
      tests = facetFilter.reduce((acc, filter) => {
        if (!filter.includes('category')) return acc

        const categoryId = filter.split(':')[1]
        return acc.filter((test) => test.groups[0]?.id === +categoryId)
      }, tests)
    }

    // only show bundles
    if (facetFilter.includes('bundle')) {
      tests = tests.filter((test) => test.isBundle)
    }
  }

  return (
    <div className="flex p-4 h-full">
      {/*<div className="w-[310px] border-r-2 border-r-gray-100 mr-2 pr-2 overflow-hidden relative h-full">*/}
      {/*  <HorsesListAside />*/}
      {/*</div>*/}

      <div className="flex-1 overflow-scroll relative">
        <div className="flex w-full justify-between items-center bg-white  sticky top-0 z-10">
          {currentHorse && (
            <HorsesComboBox
              onSelectAddNewHorse={() => {
                navigate('/add-horse')
              }}
            />
          )}
          <div className="p-2  flex space-x-4  flex-grow">
            <div className="max-w-[200px] w-full">
              <Input
                placeholder="Search tests..."
                onChange={(e) => setFilterTests(e.currentTarget.value)}
                value={filterTests}
              />
            </div>
            <FacetedFilter
              onChangeValue={setFacetFilter}
              options={[
                { icon: FaLayerGroup, label: 'Bundles', value: 'bundle' },
                ...categories.map(({ id, name }) => ({
                  label: name,
                  value: `category:${id}`,
                })),
              ]}
              title="Filter"
              value={facetFilter}
            />

            <Tabs defaultValue="recommended" onValueChange={setTab}>
              <TabsList
                className={cn('grid w-full', {
                  'grid-cols-3': addonTests.length > 0,
                  'grid-cols-2': !addonTests.length,
                })}
              >
                <TabsTrigger value="all">All Tests</TabsTrigger>

                <TabsTrigger value="recommended">Recommended</TabsTrigger>

                {addonTests.length > 0 && <TabsTrigger value="addons">Add-ons</TabsTrigger>}
              </TabsList>
            </Tabs>
          </div>

          <div>
            <Tabs defaultValue="table" onValueChange={setTestLayout}>
              <TabsList className="grid w-full grid-cols-2">
                <TabsTrigger value="table">
                  <FaTableList color="gray" size="20" />
                </TabsTrigger>

                <TabsTrigger value="grid">
                  <BsFillGrid3X2GapFill color="gray" size="20" />
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
        </div>

        <div className="absolute w-full h-full mt-4">
          {testLayout === 'table' ? (
            <TestTableList tests={tests} horse={currentHorse!} isAllTests={tab === 'all'} />
          ) : (
              <TestCardsList horse={currentHorse!} tests={tests} isAllTests={tab === 'all'} />
          )}
        </div>

        <>
          {testsLoading && (
            <div className="mt-12 text-center">
              <Spinner className="fill-edxred-500" size="lg" aria-label="Center-aligned spinner" />
              <p className="whitespace-pre-wrap">Loading tests...</p>
            </div>
          )}
        </>
      </div>

      <div>
        <div className="w-[375px] border-l-2 border-l-gray-100 ml-2 pl-2 overflow-hidden relative h-full">
          <OrderReviewAside />
        </div>
      </div>
    </div>
  )
}

TestSelectionDesktop.defaultProps = {}

export { TestSelectionDesktop }
