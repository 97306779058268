import { post } from '../api/base';
import {LoginDto} from "./useLogin";

export interface RegisterDto {
	email: string;
	firstName: string;
	lastName: string;
	mobilePhone: string;
	salutation?: unknown;
	password: string;
	passwordConfirm: string;
};

export const useRegister = () => {
	return (params: RegisterDto) => post('/api/Registration', params);
};

export const useLogin = () => {
	return (params: LoginDto) => post('/api/login', params);
};
