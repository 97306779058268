import React, { useEffect, useState } from 'react'
import { Alert, Badge, Spinner } from 'flowbite-react'
import { IOrderHorseCouponPackage } from '../context/form-context'
import currencyFormatter from '../utils/currencyFormatter'
import { useOrdersCheckCoupon } from '../hooks/useOrdersCheckCoupon'
import { HiInformationCircle } from 'react-icons/hi'
import { Heading } from '../components/Heading'
import { ScreenLayout } from '../components/ScreenLayout'
import { useCheckoutState } from '../state/checkout'
import { SubHeading } from '../components/SubHeading'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from '@edx/react-common'
import { FaArrowLeft, FaEdit } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { MdRemoveCircleOutline } from 'react-icons/md'
import Avatar from '../components/Avatar'

const OrderReview = () => {
  const { orderHorses, setCouponId, setCurrentHorse, toggleHorsePackagesTest, setAmount } = useCheckoutState()
  const navigate = useNavigate()
  const [coupon, setCoupon] = useState('')
  const [isCouponFrozen, setCouponFrozen] = useState(false)
  const [discountAmount, setDiscountAmount] = useState(-1)
  const [isCouponApplying, setCouponApplying] = useState(false)
  const [couponError, setCouponError] = useState(false)
  const checkOrdersCoupon = useOrdersCheckCoupon()
  const [accordionItem, setAccordionItem] = useState(orderHorses[0]?.id.toString())

  const orderTotalPrice = orderHorses.reduce(
    (t, items) => items.packages?.reduce((tt, items1) => items1.price + tt, t) || 0,
    0,
  )
  
  useEffect(() => {
    setAmount(orderTotalPrice)
  }, [orderTotalPrice])

   const onSubmit = () => {
    navigate('/checkout')
  }

  const onApplyCoupon = async () => {
    if (!coupon) return
    try {
      setCouponApplying(true)
      const { data: response } = await checkOrdersCoupon({
        code: coupon,
        horses: orderHorses.map((horse) => {
          return {
            id: horse.id,
            packages: horse.packages.map<IOrderHorseCouponPackage>((p) => {
              return { id: p.id }
            }),
          }
        }),
      })
      setCouponFrozen(true)
      setCouponApplying(false)
      const { discountType, data } = response
      if (discountType === 'Coupon') {
        const { id, type, amount, packages: couponPackages } = data
        let totalPrice = 0
        let totalDiscountItemPrice = 0
        for (const order of orderHorses) {
          for (const pkg of order.packages) {
            totalPrice += pkg.price
            if (couponPackages.indexOf(pkg.name) >= 0) {
              totalDiscountItemPrice += pkg.price
            }
          }
        }
        if (type === 'Percentage')
        {
          let discountAmount = Number(Math.round(totalDiscountItemPrice * amount / 100.00).toFixed(2));
          totalPrice -= discountAmount;
        }
        else totalPrice -= Math.min(totalDiscountItemPrice, amount)
        setCouponId(id)
        setDiscountAmount(totalPrice)
        setAmount(totalPrice)
        setCouponError(false)
      }
    } catch (err) {
      setCouponApplying(false)
      setCouponError(err.response.errors[0].description)
    }
  }

  if (orderHorses.length === 0) {
    navigate('/horse-selection')
  }

  return (
    <ScreenLayout>
      <Heading>Review Your Order</Heading>

      <SubHeading>Review your order details and continue to payment</SubHeading>

      <div className="form-container">
        <div className="mt-4 max-h-[45vh] overflow-scroll">
          <div className="mt-6 first:mt-0">
            <Accordion
              type="single"
              collapsible
              className="w-full"
              onValueChange={setAccordionItem}
              value={accordionItem}
            >
              {orderHorses
                .filter((horse) => horse.packages.length)
                .map((horse) => (
                  <AccordionItem key={horse.id} value={horse.id.toString()}>
                    <AccordionTrigger className="py-1">
                      <div className="flex h-11 items-center justify-between rounded-lg w-full">
                        <div className="flex space-x-2 items-center">
                          <Avatar src={horse.avatar?.url} alt="Etalon Customer Horse" />
                          <span
                            className="my-auto ml-4 max-w-[14rem] overflow-hidden text-ellipsis whitespace-nowrap text-xl font-bold text-gray-600">
                            {horse.name}
                          </span>
                        </div>

                        {accordionItem === horse.id.toString() && (
                          <Button
                            className="space-x-2 mr-2"
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setCurrentHorse(horse)
                              navigate('/test-selection')
                            }}
                            variant="outline"
                          >
                            <FaEdit /> <span>Edit</span>
                          </Button>
                        )}
                      </div>
                    </AccordionTrigger>

                    <AccordionContent>
                      <div className="text-black">
                        {horse.packages.length === 0 && <Badge color="failure">No Tests Selected</Badge>}
                        {horse.packages.length > 0 && (
                          <Table>
                            <TableHeader>
                              <TableRow>
                                <TableCell>Test</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            </TableHeader>
                            <TableBody>
                              {horse.packages.map((test) => (
                                <TableRow key={test.id}>
                                  <TableCell>{test.name}</TableCell>
                                  <TableCell className="">{currencyFormatter.format(test.price / 100)}</TableCell>
                                  <TableCell className="flex justify-end">
                                    <Button className=" rounded-full" variant="ghost"
                                            onClick={() => toggleHorsePackagesTest(test, horse.id)}>
                                      <MdRemoveCircleOutline />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        )}
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                ))}
            </Accordion>
          </div>
        </div>

        <div className="mt-auto">
          <div className="mt-2 flex grow-[2] columns-2 gap-x-2">
            <Input
              type="text"
              placeholder="Coupon Code"
              value={coupon}
              onChange={(e) => setCoupon(e.target.value)}
              disabled={isCouponFrozen || isCouponApplying || !orderHorses.find((horse) => horse.packages.length > 0)}
            />
            {!isCouponFrozen && (
              <Button variant="outline" onClick={onApplyCoupon} disabled={isCouponApplying || !coupon}>
                Apply
                {isCouponApplying && (
                  <>
                    &nbsp;
                    <Spinner className="fill-edxred-500" size="sm" aria-label="Center-aligned spinner" />
                  </>
                )}
              </Button>
            )}
          </div>
          {couponError && (
            <Alert color="failure" icon={HiInformationCircle}>
              <span className="font-medium">{couponError}</span>
            </Alert>
          )}
        </div>

        <h2 className="text-center text-xl font-bold my-2">
          Order Total:&nbsp;
          {discountAmount < 0 && (
            <span className="text-edxred-500">{currencyFormatter.format(Number(orderTotalPrice) / 100)}</span>
          )}
          {discountAmount >= 0 && (
            <>
              <span className="text-edxred-500 line-through">
                {currencyFormatter.format(Number(orderTotalPrice) / 100)}
              </span>
              &nbsp;
              <span className="text-edxgrey-500">{currencyFormatter.format(Number(discountAmount) / 100)}</span>
            </>
          )}
        </h2>

        <Button className="w-full py-4" onClick={onSubmit} variant="brand">
          Continue to Payment
        </Button>

        <div
          className="text-gray-500 mt-4 flex items-center cursor-pointer space-x-2"
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft /> <span>Go Back</span>
        </div>
      </div>
    </ScreenLayout>
  )
}

export default OrderReview
