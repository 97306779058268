import React, { PropsWithChildren } from 'react'
import { Header } from '../Header'
import { Footer } from '../Footer'

function DesktopAppLayout({ children }: PropsWithChildren) {
  return (
    <div className="flex flex-col h-screen max-h-screen">
      <Header />

      <div className="flex-grow overflow-hidden">{children}</div>

      <Footer />
    </div>
  )
}

DesktopAppLayout.defaultProps = {}

export { DesktopAppLayout }
