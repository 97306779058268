export const BASE_URL =
    import.meta.env.VITE_APP_BASE_URL ?? "https://localhost:5001";


export const BASE_WEB_URL =
    import.meta.env.VITE_APP_BASE_WEB_URL ?? "https://localhost:3000";

export const BASE_IMAGE_URL = 
    import.meta.env.VITE_APP_IMAGES_BASE_URL ?? "https://localhost:3000";

export function getEnvParams() {
    return {
        stripeApiKey: import.meta.env.VITE_APP_STRIPE_API_KEY,
        paypalClientId: import.meta.env.VITE_APP_PAYPAL_CLIENT_ID,
        dataDogClientToken: import.meta.env.VITE_APP_DATADOG_CLIENT_TOKEN,
        dataDogTags: import.meta.env.VITE_APP_DD_TAGS,
        googleMapKey: import.meta.env.VITE_APP_GOOGLE_SCRIPT_KEY,
        fullstoryOrgId: import.meta.env.VITE_APP_FULLSTORY_ORGID,
        googleTagManagerId: import.meta.env.VITE_APP_GOOGLE_TAG_MANAGER_ID,
    };
}
