import { fetcher } from './base'
import { AuthService } from '../services/auth'

export type TUser = {
  id: number,
  firstName: string,
  lastName: string,
  email: string,
  phone?: string,
  mobile?: string,
  name: string,
}

export const UsersService = {
  async getCurrentUser() {
    try {
      const res = await fetcher('/api/UsersCurrent')

      return {
        id: res.data.id,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        email: res.data.email,
        phone: res.data.phone,
        mobile: res.data.mobile,
        name: res.data.name,
      }
    } catch (e: any) {
      if (e?.status === 401) {
        AuthService.emitAuthExpiredEvent()
      }
      // ignore
      console.log('e', e)
      return undefined
    }
  },
}
