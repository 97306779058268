import { post } from '../api/base';

export interface StripePaymentIntentDto {
	amount: number;
	description: string;
	receiptEmail: string;
};

export const useStripePaymentIntent = () => {
	return (params: StripePaymentIntentDto) => post('/api/StripePaymentIntent', params);
};
