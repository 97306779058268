import React, { PropsWithChildren, useMemo } from 'react'
import etalonLogo from '../../assets/etalon-logo-color.png'
import { MdLogout } from 'react-icons/md'
import { Badge, ContainerDimensionsContextProvider } from '@edx/react-common'
import { useAuthState } from '../../state/auth'
import { useCheckoutState } from '../../state/checkout'
import { FaCartShopping } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
import { clearSWRCache } from '../../utils/storage'

function MobileAppLayout({ children }: PropsWithChildren) {
  const authState = useAuthState()
  const checkoutState = useCheckoutState()
  const navigate = useNavigate()

  const testsInCart = useMemo(() => {
    return checkoutState.orderHorses.reduce((acc, horse) => {
      return acc + horse.packages.length
    }, 0)
  }, [checkoutState.orderHorses])

  return (
    <ContainerDimensionsContextProvider asViewport>
      <div className="flex h-screen flex-col">
        <div className="fixed top-0 h-16  w-full">
          <header className="mt-2 flex items-end justify-between px-2 py-0 lg:items-center lg:p-6">
            <a href="https://etalondx.com" target="_blank">
              <img
                alt="Etalon's logo, which consists of a horse with DNA Helix along its body."
                className="mx-auto w-[150px] lg:w-[230px]"
                src={etalonLogo}
              />
            </a>

            <div className="flex items-end space-x-3">
              {!!testsInCart && (
                <div
                  className="flex items-center justify-center text-white space-x-1 rounded-full bg-edxred-500 px-3 py-1.5 text-lg cursor-pointer"
                  onClick={() => navigate('/order-review')}
                >
                  <FaCartShopping /> <span>Checkout</span> <Badge variant="secondary">{testsInCart || 0}</Badge>
                </div>
              )}

              {authState.isAuthenticated && (
                <div
                  className="cursor-pointer bg-edxgrey-500/50 rounded-full w-10 h-10 flex items-center justify-center text-white"
                  onClick={() => {
                    authState.__reset()
                    checkoutState.__reset()
                    clearSWRCache()
                  }}
                >
                  <MdLogout />
                </div>
              )}
            </div>
          </header>
        </div>

        <main className="flex flex-1 flex-col overflow-y-auto px-2 lg:px-6 mt-16">{children}</main>
      </div>
    </ContainerDimensionsContextProvider>
  )
}

MobileAppLayout.defaultProps = {}

export { MobileAppLayout }
