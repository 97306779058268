import { Field } from 'formik'
import TextInputField from './TextInputField'

type LabelType = 'onlyLabel' | 'onlyPlaceholder' | 'both' | 'none'

interface FormikTextInputFieldProps {
  id?: string
  fieldName: string
  label: string
  type?: string
  labelType?: LabelType
  color?: string
  showEye?: boolean
}

export const FormikTextInputField = (props: FormikTextInputFieldProps) => {
  return (
    <Field
      name={props.fieldName}
      render={({ field, form }) => (
        <TextInputField
          {...props}
          error={form.touched[props.fieldName] && form.errors[props.fieldName] ? form.errors[props.fieldName] || 'Error' : undefined}
          onBlur={field.onBlur}
          onChange={field.onChange}
          value={form.values[props.fieldName]}
        />
      )}
    />
  )
}
