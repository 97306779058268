import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import useSWR from 'swr'
import { fetcher } from '../api/base'
import { useOrdersFinalize } from '../hooks/useOrdersFinalize'
import { Heading } from '../components/Heading'
import { ScreenLayout } from '../components/ScreenLayout'
import { Button } from '../components/Button'
import { useCheckoutState } from '../state/checkout'
import { useAuthState } from '../state/auth'
import { FaDownload } from 'react-icons/fa'
import { Separator } from '@edx/react-common'
import { FaCartShopping } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
import APHISPermit from '../../public/Etalon Equine Genetics APHIS Permid 2024-2025.pdf'
import { UsersService } from '../api/UsersService'

const CheckoutSuccess = () => {
  const { user } = useAuthState()
  const checkoutState = useCheckoutState()
  const navigate = useNavigate()

  const { data: ordersResponse, error } = useUserOrders(user?.id?.toString())
  const orderId = ordersResponse?.data ? ordersResponse.data[0].order?.id : null
  const horseId = ordersResponse?.data ? ordersResponse.data[0]?.id : null
  const orderTotal = ordersResponse?.data ? ordersResponse.data[0]?.order?.total : null

  useEffect(() => {
    
    // reset the user's checkout state when they've finished the sale
    checkoutState.__reset()
  }, [])

  useEffect(() => {
    if (orderId && sessionStorage.getItem('stripePaymentProcessing')) {
      sessionStorage.removeItem('stripePaymentProcessing')
    }
  }, [orderId])

  /* Rewardful Tracker START */
  useEffect(() => {
    if ((window as any).rewardful && user.email) {
      (window as any).rewardful('convert', {
        email: user.email,
      })
    }
  }, [user.email])
  /* Rewardful Tracker END */

  const downloadForm = (orderId: string, horseId: string) => {
    getForm(orderId, horseId)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new Blob([blob], { type: 'application/pdf' })
        const fileNameObj: { name: string; extension: string } = { name: 'submission-form' || 'file', extension: '' }
        fileNameObj.extension = 'pdf'
        const fullFileName = `${fileNameObj.name}.${fileNameObj.extension}`
        const link = document.createElement('a')
        link.download = fullFileName
        link.href = URL.createObjectURL(file)
        link.click()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <ScreenLayout>
      <Heading>Thank you for your order!</Heading>

      <div className="mt-3 flex h-48 w-full items-center justify-center">
        <iframe
          className="rounded"
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/EAqhb8zfk6k`}
        ></iframe>
      </div>

      <div className="mt-4">
        <div className="flex justify-start">
          <Heading>Next steps:</Heading>
        </div>
        <ol className="list-inside list-decimal">
          <li>Download your Sample Submission Form(s) linked below</li>
          <li>Check your email and click the link to activate your Etalon Dashboard</li>
          <li>Collect and send in your sample</li>
        </ol>
      </div>

      <div className="mt-3 md:mt-6">
        <Button className="w-full py-3 md:py-4" onClick={() => downloadForm(orderId, horseId)}>
          <FaDownload className="mr-2" /> Download Submission Form
        </Button>
        <div className="mt-2 text-center">
          <a
            className="cursor-pointer text-edxred-500 text-sm hover:underline"
            href="https://accounts.etalondiagnostics.com"
            target={'_blank'}
            rel="noreferrer"
          >
            Print Later from My Account or Email
          </a>
        </div>
      </div>

      <div className="mt-4 md:mt-6">
        <div className="text-center text-sm">
          For International samples, please include this Import Permit when mailing.
        </div>
        <a href={'/EtalonEquineGeneticsAPHISPermit2024-2025.pdf'} download={'APHIS_Permit.pdf'}>
          <Button className="mt-2 w-full py-3 md:py-4">
            <FaDownload className="mr-2" /> Download APHIS Permit
          </Button>
        </a>
      </div>

      <Separator className="my-3 md:my-6" />

      <div className="mt-3 md:mt-6">
        <Button outline className="w-full py-4" onClick={() => navigate('/horse-selection')}>
          <FaCartShopping className="mr-2" /> Start Another Order
        </Button>
      </div>
    </ScreenLayout>
  )
}

export const useUserOrders = (userId: string) => {
  return useSWR(`/api/UserProfileOrders?userId=${userId}&currentPage=1&countPerPage=1`, fetcher)
}

export const getForm = (orderId: string, horseId: string) => {
  return fetcher(`/api/HorseSubmissionForm?OrderId=${orderId}&HorseId=${horseId}`, {}, 'application/pdf')
}

export default CheckoutSuccess
