import { BASE_URL } from '../config/environment'
import { EdxApi } from '@edx/api'
import { useAuthState } from '../state/auth'
import { AuthService } from '../services/auth'

export class APIError extends Error {
  public status: number
  public response: any

  constructor(statusText, status, response) {
    super(statusText)

    this.status = status
    this.response = response
  }
}

export const fetcher = (url: string, init: any = {}, contentType: string = 'application/json') => {
  if (url.startsWith('/')) url = BASE_URL + url
  if (init.headers == null) init.headers = {}
  const accessToken = useAuthState.getState().token
  if (accessToken) init.headers['Authorization'] = `Bearer ${accessToken}`

  if (!init.headers['Content-Type']) init.headers['Content-Type'] = contentType
  else if (init.headers['Content-Type'] === 'multipart/form-fixtures') {
    delete init.headers['Content-Type']
  }

  return fetch(url, init)
    .then((response) => {
      if (!response.ok) {
        if (response.status === 401) {
          AuthService.emitAuthExpiredEvent()
        }

        return response.json().then((errors) => {
          throw new APIError(response.statusText, response.status, errors)
        })
      }
      if (contentType === 'application/pdf') return response
      return response.json()
    })
    .catch((error) => {
      // if status code is 401 redirect to /login
      if (error?.status === 401) {
        AuthService.emitAuthExpiredEvent()
      }
      throw error
    })
}

export const post = makeMutationMethod('POST')
export const put = makeMutationMethod('PUT')
export const patch = makeMutationMethod('PATCH')
export const del = makeMutationMethod('DELETE')

export function makeMutationMethod(method) {
  return async (url, data) => {
    let body, contentType
    if (data instanceof FormData) {
      body = data
      contentType = 'multipart/form-fixtures'
    } else if (data != null) {
      body = JSON.stringify(data)
      contentType = 'application/json'
    }

    const init = {
      method,
      headers: {
        'Content-Type': contentType,
      },
      body,
    }

    return fetcher(url, init)
  }
}

export const edxApi = new EdxApi({
  baseURL: BASE_URL,
})
