import { useMemo } from 'react'
import { Field } from 'formik'
import { SelectInputField, SelectInputFieldProps } from './SelectInputField'

interface FormikSelectInputFieldProps extends Omit<SelectInputFieldProps, 'onValueChange' | 'value'> {}

export const FormikSelectInputField = (props: FormikSelectInputFieldProps) => {
  return (
    <Field
      name={props.fieldName}
      render={({ field, form }) => {
        return (
          <SelectInputField
            {...props}
            fieldName={props.fieldName}
            error={
              form.errors[props.fieldName] && form.touched[props.fieldName] ? form.errors[props.fieldName] : undefined
            }
            onValueChange={(value: string) => {
              form.setFieldValue(props.fieldName, value)
            }}
            value={field.value}
          />
        )
      }}
    />
  )
}
