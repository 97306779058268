import { create } from 'zustand'
import { TUser } from '../api/UsersService'
import { LocalStorageService } from '../services/local-storage'

export type AuthState = {
  __reset: () => void
  isAuthenticated: boolean
  refreshToken: string
  setRefreshToken: (token: string | undefined) => void
  setToken: (token: string | undefined) => void
  setUser: (user: TUser) => void
  token: string
  user: TUser
}

const DEFAULT_AUTH_STATE = {
  isAuthenticated: false,
  token: '',
  refreshToken: '',
  user: {} as any,
}

export const useAuthState = create<AuthState>((set) => ({
  ...(LocalStorageService.get('@@authState') || structuredClone(DEFAULT_AUTH_STATE)),
  __reset: () => set(structuredClone(DEFAULT_AUTH_STATE)),
  setRefreshToken: (token) => set({ refreshToken: token }),
  setToken: (token) => set({ isAuthenticated: !!token, token }),
  setUser: (user) => set({ user }),
}))

useAuthState.subscribe((state, prevState) => {
  LocalStorageService.set('@@authState', useAuthState.getState())
})
