import { Label } from 'flowbite-react'
import { ReactNode, useMemo, useState } from 'react'
import { IconBaseProps } from 'react-icons'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { Input } from '@edx/react-common'

type LabelType = 'onlyLabel' | 'onlyPlaceholder' | 'both' | 'none'

interface TextInputFieldProps {
  color?: string
  error?: string
  fieldName: string
  id?: string
  label: string
  labelType?: LabelType
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  showEye?: boolean
  type?: string
  value?: string
}

const TextInputField = (props: TextInputFieldProps) => {
  const {
    color,
    error,
    fieldName,
    id,
    label,
    labelType = 'onlyPlaceholder',
    onBlur,
    onChange,
    showEye,
    type,
    value,
  } = props

  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const [rightIcon, fieldType] = useMemo(() => {
    let _rightIcon: ReactNode | null = null

    if (showEye) {
      _rightIcon = showPassword ? (
        <FaEye onClick={togglePasswordVisibility} className="icon-eye" />
      ) : (
        <FaEyeSlash onClick={togglePasswordVisibility} className="icon-eye" />
      )
    }

    let _type = showPassword ? 'text' : type || 'text'

    return [_rightIcon, _type]
  }, [showPassword, showEye, type])

  return (
    <div id={id}>
      {(labelType === 'onlyLabel' || labelType === 'both') && <Label htmlFor={fieldName} value={label} />}
      <div className="relative">
        <Input
          className="focus-visible:ring-0 focus-visible:ring-offset-0"
          id={'textinputfield_' + fieldName}
          color={error && 'red'}
          name={fieldName}
          type={fieldType}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          placeholder={labelType === 'onlyPlaceholder' || labelType === 'both' ? label : ''}
        />
        <div className="absolute right-3 bottom-4">{rightIcon}</div>
      </div>
      {error && <div className="text-edxred-300">{error}</div>}
    </div>
  )
}
export default TextInputField
