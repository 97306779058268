import { useState } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { Button } from './Button'
import {useUpdateEffect} from "ahooks";
import { useOrdersCreate } from '../hooks/useOrdersCreate';
import { useCheckoutState } from '../state/checkout';
import TagManager from "react-gtm-module";
import {useNavigate} from "react-router-dom";

const StripeCheckoutForm = ({ onProcessingChange }: {
  onProcessingChange: (isProcessing: boolean) => void
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const [message, setMessage] = useState<string>()
  const [isProcessing, setIsProcessing] = useState(false)
  const createOrder = useOrdersCreate()
  const { orderHorses, couponId, setAmount, setOrderId, clientSecret, buyerToken, amount: orderTotal } = useCheckoutState()
  const navigate = useNavigate()

  const createCurrentOrder = async () => {
    try {
      const res = await createOrder({
        horses: orderHorses.filter((horse) => horse.packages?.length),
        paymentMethod: 'Stripe',
        coupon: !couponId ? undefined : { id: couponId },
        transactionId: clientSecret,
        buyerToken
      })
      const { amount, id } = res.data
      setAmount(amount)
      setOrderId(id);
      return id;
    } catch (err) {
      console.error("Failed to create an order: ", err);
      return NaN;
    }
  }

  const handleStripePayment = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      console.warn('Stripe.js has not loaded yet.')
      return
    }

    setIsProcessing(true)

    sessionStorage.setItem('stripePaymentProcessing', 'true')
    const orderId = await createCurrentOrder()
    if (orderId) {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/checkout-success`,
        },
        redirect: 'if_required'
      })
      sessionStorage.removeItem('stripePaymentProcessing')

      if(!error){
        console.log("Event: Purchase");
        console.log("Order Id: " + orderId);
        console.log("Order Total: " + (orderTotal/100));
        // Google Tag Manager tracker
        TagManager.dataLayer({
          dataLayer: {
            event: 'Purchase',
            ecommerce: {
              transaction_id: orderId,
              value: orderTotal / 100,
              currency: 'USD',
            },
          },
        })
        navigate('/checkout-success')
      }
      else if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message)
      } else {
        setMessage('An unexpected error occured.')
      }
    } else {
      setMessage('Failed to create an order. Please try again later.')
    }

    setIsProcessing(false)
  }

  useUpdateEffect(() => {
    onProcessingChange(isProcessing)
  }, [isProcessing])

  return (
    <div>
      <form onSubmit={handleStripePayment} id="stripe-form">
        <PaymentElement />
        {message && <p style={{ color: 'red' }}>{message}</p>}
        <Button className="mt-2 w-full py-4" type="submit" form="stripe-form" id="submit" disabled={isProcessing}>
          Complete Payment
        </Button>
      </form>
    </div>
  )
}

export default StripeCheckoutForm
