import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react'
import classnames from 'classnames'

type ButtonProps = PropsWithChildren<{
  className?: string
  outline?: boolean
}> &
  ButtonHTMLAttributes<HTMLButtonElement>

function Button({ children, className = '', outline, ...buttonProps }: ButtonProps) {
  return (
    <button
      className={classnames(`flex items-center justify-center rounded-lg px-6 py-2 hover:cursor-pointer ${className}`, {
        'bg-edxred-500 text-white hover:bg-edxred-700': !outline,
        'border border-edxred-500 text-edxred-500 hover:bg-edxred-700 hover:text-white': outline,
      })}
      {...buttonProps}
    >
      {children}
    </button>
  )
}

Button.defaultProps = {}

export { Button }
