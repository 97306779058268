import { IOrderHorseCoupon } from '../context/form-context';
import { post } from '../api/base';

export interface OrdersCheckCouponDto {
	horses: IOrderHorseCoupon[];
  code: string;
};

export const useOrdersCheckCoupon = () => {
	return (params: OrdersCheckCouponDto) => post('/api/OrdersCheckCoupon', params);
};
