import { Label } from 'flowbite-react'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@edx/react-common'
import { useMemo } from 'react'

type LabelType = 'onlyLabel' | 'onlyPlaceholder' | 'both' | 'none'

export interface ValueLabelOptionType {
  value: number | string
  label: string
}

export interface SelectInputFieldProps {
  fieldName: string
  label: string
  options: ValueLabelOptionType[]
  labelType?: LabelType
  onValueChange: (value: string) => void
  value: string
  error?: string | undefined
}

export const SelectInputField = (props: SelectInputFieldProps) => {
  const { fieldName, label, options, labelType = 'onlyPlaceholder', onValueChange, value, error } = props

  const optionsList = useMemo(
    () =>
      options.map((option) => {
        return <SelectItem value={option.value.toString()}>{option.label}</SelectItem>
      }),
    [options],
  )

  return (
    // Add the translate="no" attribute to prevent chrome translator from breaking the select component
    <div translate="no">
      {(labelType === 'onlyLabel' || labelType === 'both') && <Label htmlFor={fieldName} value={label} />}
      <Select name={fieldName} value={value} onValueChange={onValueChange}>
        <SelectTrigger className="focus-visible:ring-0 focus-visible:ring-offset-0">
          <SelectValue placeholder={`Select a ${label}`} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>{label}</SelectLabel>
            {optionsList}
          </SelectGroup>
        </SelectContent>
      </Select>

      {error && <div className="text-edxred-300">{error}</div>}
    </div>
  )
}
