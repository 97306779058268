import React from 'react'
import TestCard from './TestCard'
import { IOrderHorse, IPackage } from '../state/checkout'
import { Card } from 'flowbite-react'

interface TestsListProps {
  horse: IOrderHorse
  tests: IPackage[]
  isAllTests?: boolean
}

function TestCardsList({ horse, tests, isAllTests }: TestsListProps) {
  const grouped = tests.reduce(
    (acc, test) => {
      const category = test.groups[0]?.name || 'Uncategorized'
      const categoryIndex = acc.findIndex((group) => group.category === category)
      if (categoryIndex === -1) {
        acc.push({ category, tests: [test] })
      } else {
        acc[categoryIndex].tests.push(test)
      }
      return acc
    },
    [] as { category: string; tests: IPackage[] }[],
  )

  return (
    <div className="relative pb-4">
      {grouped.map((group, groupId) => (
        <div className="" key={group.category}>
          <h2 className="text-lg text-edxgrey-500 sticky top-0 bg-white font-bold py-2">{group.category}</h2>
          <div className="grid grid-cols-1 gap-2 xl:grid-cols-3 xl:gap-4">
            {group.tests.map((test) => (
              <div key={test.id}>
                <TestCard key={test.id} test={test} horseId={horse.id} />
              </div>
            ))}
            {isAllTests && groupId === grouped.length - 1 && (
              <div>
                <Card className={'flex flex-col justify-between h-full p-1 md:p-2 mt-2 md:mt-4 first:mt-0 cursor-pointer w-full border-none shadow-none'}>
                  <div className="text-center text-lg font-semibold text-gray-400">
                    If you wish to purchase a single test not currently listed, please contact our Customer Service Team at info@etalondx.com or +1-650-380-2995 and they will gladly assist you.
                  </div>
                </Card>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

TestCardsList.defaultProps = {}

export { TestCardsList }
