import React, { useState } from 'react'
import { Formik, useFormik } from 'formik'
import * as Yup from 'yup'
import { Spinner } from 'flowbite-react'
import { useLogin } from '../hooks/useLogin'
import { useNavigate } from 'react-router-dom'
import { Heading } from '../components/Heading'
import { ScreenLayout } from '../components/ScreenLayout'
import { Button } from '../components/Button'
import { useAuthState } from '../state/auth'
import { UsersService } from '../api/UsersService'
import { LocalStorageService } from '../services/local-storage'
import { FormikTextInputField } from '../components/FormikTextInputField'

const LoginForm = () => {
  const login = useLogin()
  const authState = useAuthState()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState(false)
  const navigate = useNavigate()

  if (isLoading)
    return (
      <div className="mt-12 text-center flex flex-col items-center justify-center">
        <Spinner className="fill-edxred-500" size="lg" aria-label="Center-aligned spinner" />
        <p className="whitespace-pre-wrap">Logging in...</p>
      </div>
    )

  return (
    <ScreenLayout>
      <Heading>Welcome! Log in here.</Heading>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={Yup.object({
          email: Yup.string().email().required('Required'),
          password: Yup.string()
            .required('Required')
            .min(6, 'Password must be at least 6 characters long.')
            .max(100, 'Password must be less than 100 characters long.'),
        })}
        onSubmit={(values) => {
          LocalStorageService.set('is_edx_user', true)

          setIsLoading(true)
          login({
            userName: values.email,
            password: values.password,
          })
            .catch((err) => {
              console.error('@Error: ', err)
              setError(true)
              setIsLoading(false)
            })
            .then((res) => {
              const { tokenAccess, tokenRefresh } = res.data
              authState.setToken(tokenAccess)
              authState.setRefreshToken(tokenRefresh)

              return UsersService.getCurrentUser()
            })
            .catch((err) => {
              console.error('@Error: ', err)
              setError(true)
              setIsLoading(false)
            })
            .then((user) => {
              if (user) {
                authState.setUser(user)
                navigate('/horse-selection')
              } else {
                console.warn('No user found')
                setError(true)
                setIsLoading(false)
              }
            })
        }}
      >
        {(formik) => (
          <>
            <div id="login" className="mt-6 flex  flex-1 flex-col gap-4">
              <FormikTextInputField fieldName="email" label="Email Address" type="email" />
              <FormikTextInputField fieldName="password" label="Password" type="password" showEye={true} />
              {error && <div className="text-edxred-300">The username or password is incorrect.</div>}
              <Button disabled={formik.isSubmitting} onClick={() => formik.handleSubmit()}>
                Log In
              </Button>
            </div>
          </>
        )}
      </Formik>

      <div className="mt-4 flex justify-between">
        <div>
          New User?{' '}
          <span className="cursor-pointer text-edxred-500 hover:underline" onClick={() => navigate('/create-user')}>
            Sign up here!
          </span>
        </div>

        <div>
          <a
            className="cursor-pointer text-edxred-500 hover:underline"
            href="https://accounts.etalondiagnostics.com/forgot-password"
            target={'_blank'}
          >
            Forgot Password?
          </a>
        </div>
      </div>
    </ScreenLayout>
  )
}

export default LoginForm
