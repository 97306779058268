import { Badge, Checkbox, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@edx/react-common'
import { IOrderHorse, IPackage, useCheckoutState } from '../state/checkout'
import currencyFormatter from '../utils/currencyFormatter'
import {  useMemo } from 'react'
import { FaLayerGroup } from 'react-icons/fa'

interface TestTableListProps {
  horse?: IOrderHorse
  tests: IPackage[]
  isAllTests?: boolean
}

export function TestTableList({ horse, tests, isAllTests }: TestTableListProps) {
  const [toggleTest, setHorsePackages] = useCheckoutState((state) => [
    state.toggleHorsePackagesTest,
    state.setHorsePackages,
  ])

  const [allCheckedState, selectedTestsByTestId] = useMemo(() => {
    const selected =
      horse?.packages.reduce(
        (acc, test) => {
          acc[test.id] = true
          return acc
        },
        {} as Record<number, true>,
      ) ?? {}

    let allState: boolean | 'indeterminate' = false

    if (tests.length > 0 && Object.keys(selected).length === tests.length) {
      allState = true
    } else if (Object.keys(selected).length > 0) {
      allState = 'indeterminate'
    }

    return [allState, selected]
  }, [horse?.packages])

  const handleCheckAll = () => {
    if (Object.keys(selectedTestsByTestId).length > 0) {
      setHorsePackages([], horse?.id)
    } else {
      setHorsePackages(tests, horse?.id)
    }
  }

  return (
    <div className="relative w-full overflow-auto" id="table-test-container">
      <Table>
        <TableHeader>
          <TableRow className="[&>*]:px-2">
            <TableHead className="w-[32px]">
              <Checkbox checked={allCheckedState} id="select-all" onCheckedChange={handleCheckAll} />
            </TableHead>
            <TableHead className="min-w-[200px]">Select Test</TableHead>
            <TableHead className="min-w-[200px]">Category</TableHead>
            <TableHead>Description</TableHead>
            <TableHead className="text-right">Price</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {tests.map((test) => (
            <TableRow className="[&>*]:py-2 [&>*]:px-2" key={test.id}>
              <TableCell>
                <Checkbox
                  checked={!!selectedTestsByTestId[test.id]}
                  id={`select-${test.id}`}
                  onCheckedChange={() => toggleTest(test)}
                />
              </TableCell>
              <TableCell className=" font-semibold">{test.name}</TableCell>
              <TableCell className="capitalize font-semibold">{test.groups?.[0]?.name}</TableCell>
              <TableCell>{test.isBundle && <Badge className="text-edxred-500 mr-1" variant="outline"><FaLayerGroup className="mr-1" /> Bundle</Badge>}{test.description}</TableCell>
              <TableCell className=" font-semibold text-right">{currencyFormatter.format(test.price / 100)}</TableCell>
            </TableRow>
          ))}
          {isAllTests && (
            <TableRow>
              <TableCell colSpan={5}>
                <div className="text-center text-lg font-semibold text-gray-400 my-4 px-16">
                  If you wish to purchase a single test not currently listed, please contact our Customer Service Team at info@etalondx.com or +1-650-380-2995 and they will gladly assist you.
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}
