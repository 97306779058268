import parseJwt from '../utils/parseJwt'
import jwt from 'jwt-decode'

const emitAuthExpiredEvent = () => {
  console.info('** AuthExpiredEvent **')
  const event = new Event('auth-expired')
  window.dispatchEvent(event)
}

const listenAuthExpiredEvent = (callback: () => void) => {
  window.addEventListener('auth-expired', callback)

  return () => {
    window.removeEventListener('auth-expired', callback)
  }
}

const getAccessTokenData = () => {
  const token = localStorage.getItem('access_token')?.toString()
  const data = parseJwt(token)
  return data
}

const validateAccessToken = (accessToken: string): boolean => {
  if (!accessToken) return false

  try {
    const { exp } = jwt<{
      exp: number
      iat: number
    }>(accessToken)

    if (exp * 1000 < Date.now()) {
      return false
    }

    return true
  } catch (err) {
    return false
  }
}

export const AuthService = {
  emitAuthExpiredEvent,
  getAccessTokenData,
  listenAuthExpiredEvent,
  validateAccessToken,
}
