import React from 'react'
import etalonHorseLogoSvg from '@/assets/etalon-horse-logo.png'

interface FooterProps {}

function Footer({}: FooterProps) {
  return (
    <div className="text-white py-4 flex justify-center bg-edxred-700 w-screen px-4">
      <div className="flex space-x-4 items-center justify-between w-full">
        <img className="fill-white text-white" src={etalonHorseLogoSvg} alt="Etalon Equine Genetics" width={100} />
        <div className="mt-4 text-center">© {new Date().getFullYear()} Etalon Inc.</div>
      </div>
    </div>
  )
}

export { Footer }
