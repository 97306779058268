const KEYS = {
  '@@authState': '@@authState',
  '@@checkoutState': '@@checkoutState',
  // boolean to indicate if the visitor has logged in as user before
  is_edx_user: 'is_edx_user',
}

export const LocalStorageService = {
  get: <T>(key: keyof typeof KEYS): T | undefined => {
    const value = localStorage.getItem(key)
    try {
      if (value) {
        return JSON.parse(value) as T
      } else {
        return undefined
      }
    } catch (e: unknown) {
      if (value) {
        return value as T
      } else {
        return undefined
      }
    }
  },

  set: (key: keyof typeof KEYS, value: any): void => {
    if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value))
    } else {
      localStorage.setItem(key, value)
    }
  },
}
