import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import TagManager from 'react-gtm-module'
import { Spinner } from 'flowbite-react'

import { useRegister } from '../hooks/useRegister'
import { useLogin } from '../hooks/useLogin'
import { useNavigate } from 'react-router-dom'
import { Heading } from '../components/Heading'
import { ScreenLayout } from '../components/ScreenLayout'
import { Button } from '../components/Button'
import { useAuthState } from '../state/auth'
import { LocalStorageService } from '../services/local-storage'
import { useToast } from '@edx/react-common'
import { FormikTextInputField } from '../components/FormikTextInputField'
import { UsersService } from '../api/UsersService'

const CreateUserForm = () => {
  const { toast } = useToast()
  const { setUser, setToken, setRefreshToken } = useAuthState()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const registerUser = useRegister()
  const login = useLogin()
  const navigate = useNavigate()

  if (isLoading)
    return (
      <div className="mt-12 text-center flex flex-col items-center justify-center">
        <Spinner className="fill-edxred-500" size="lg" aria-label="Center-aligned spinner" />
        <p className="whitespace-pre-wrap">Creating user...</p>
      </div>
    )

  return (
    <ScreenLayout>
      <Heading>Welcome! Sign up for a FREE account to order testing.</Heading>
      <Formik
        id="create-user"
        onSubmit={(values, event) => {
          setIsLoading(true)
          registerUser(values)
            .then((res) => {
              login({
                userName: values.email,
                password: values.password,
              })
                .then((res) => {
                  const { tokenAccess, tokenRefresh } = res.data
                  setToken(tokenAccess)
                  setRefreshToken(tokenRefresh)
                  LocalStorageService.set('is_edx_user', true)
                  return UsersService.getCurrentUser()
                })
                .catch((err) => {
                  console.error('@Error: ', err)
                  setIsLoading(false)
                })
                .then((user) => {
                  if (user) {
                    console.log("Event: CompleteRegistration");
                    // Google Tag Manager tracker
                    TagManager.dataLayer({
                      dataLayer: {
                        event: 'CompleteRegistration',
                      },
                    })
                    setUser(user)
                    console.log('nav to start', )
                    navigate('/start')
                  }
                  else {
                    console.warn('No user found')
                    setIsLoading(false)
                  }
                })
            })
            .catch((err) => {
              const isDuplicateUser = err?.response?.errors?.some?.((err) => err?.errorCode === 'DuplicateUser')
              setIsLoading(false)
              if (isDuplicateUser) {
                event.setFieldError('email', `${values.email} already has an account`)

                toast({
                  variant: 'destructive',
                  title: 'Duplicate User Found',
                  description: `It appears there is already a user account for ${values.email}, please try logging in.`,
                })
              } else {
                console.error('CreateUserForm#onSubmit', err)

                toast({
                  variant: 'destructive',
                  title: 'Error',
                  description: 'There was an error creating your account. Please try again.',
                })
              }
            })
        }}
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          mobilePhone: '',
          password: '',
          passwordConfirm: '',
        }}
        validationSchema={Yup.object({
          firstName: Yup.string().required('Required'),
          lastName: Yup.string().required('Required'),
          email: Yup.string().email().required('Required'),
          mobilePhone: Yup.string().required('Required'),
          password: Yup.string()
            .required('Required')
            .min(6, 'Password must be at least 6 characters long.')
            .max(100, 'Password must be less than 100 characters long.'),
          passwordConfirm: Yup.string().when('password', (password, field) =>
            password ? field.required('Required').oneOf([Yup.ref('password')], 'Passwords must match.') : field,
          ),
        })}
        validateOnMount={false}
        validateOnChange={false}
      >
        {(formik) => (
          <div className="flex flex-col space-y-2">
            <div className="flex justify-between space-x-1">
              <div className="flex-1">
                <FormikTextInputField fieldName="firstName" label="First Name" />
              </div>
              <div className="flex-1">
                <FormikTextInputField fieldName="lastName" label="Last Name" />
              </div>
            </div>

            <FormikTextInputField fieldName="email" label="Email Address" type="email" />

            <FormikTextInputField fieldName="mobilePhone" label="Phone Number" type="tel" />

            <div className="flex justify-between space-x-1">
              <div className="flex-1">
                <FormikTextInputField fieldName="password" label="Password" type="password" showEye={true} />
              </div>

              <div className="flex-1">
                <FormikTextInputField
                  fieldName="passwordConfirm"
                  label="Confirm Password"
                  type="password"
                  showEye={true}
                />
              </div>
            </div>
            <Button disabled={formik.isSubmitting} form="create-user" onClick={() => formik.handleSubmit()}>
              Sign Up
            </Button>
          </div>
        )}
      </Formik>

      <div className="mt-4">
        Already have an account?{' '}
        <span className="cursor-pointer text-edxred-500 hover:underline" onClick={() => navigate('/login')}>
          Log in here!
        </span>
      </div>
    </ScreenLayout>
  )
}

export default CreateUserForm
