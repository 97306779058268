import { post } from '../api/base'

export interface HorseAvatarUpdateDto {
  horseId: number
  avatar: File
}

export function avatarGalleryRequestConverter(horseId: number, file: File) {
  const form = new FormData()
  form.append('horseId', String(horseId))
  form.append('file', file)

  return form
}

export const useHorseAvatarUpdate = () => {
  return (params: HorseAvatarUpdateDto) =>
    post('/api/HorseAvatarUpdate', avatarGalleryRequestConverter(params.horseId, params.avatar))
}
