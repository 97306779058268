import { twc } from 'react-twc'
import { PropsWithChildren } from 'react'

const Wrapper = twc.div`flex flex-col items-center justify-center`

export const ScreenLayout = ({ children }: PropsWithChildren) => {
  return (
    <Wrapper>
      <div className="max-w-[400px] w-full">{children}</div>
    </Wrapper>
  )
}
