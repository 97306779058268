import {FullStory} from "@fullstory/browser";
import {useAuthState} from "../state/auth";
import {useEffect} from "react";

/**
 * This method is used to update the FullStory user with the current user's information.
 * It should be called when any changes in auth state change.
 */
export function useFullStory() {
    const {isAuthenticated, user} = useAuthState()
    useEffect(() => {
        if (isAuthenticated  && user?.id) {
            FullStory("setIdentity", {
                uid: user.id.toString(),
                consent: true,
                properties: {
                    email: user.email,
                    displayName: `${user.firstName} ${user.lastName}`,
                    phone: user.phone,
                },
            });
        } else {
            FullStory("restart");
        }
    }, [isAuthenticated, user])
}
