import currencyFormatter from '../utils/currencyFormatter'
import { Card, Checkbox } from '@edx/react-common'
import { IPackage, useCheckoutState } from '../state/checkout'

type TestCardProps = {
  test: IPackage
  horseId?: number
}

const TestCard = ({ test, horseId }: TestCardProps) => {
  const [toggleTest] = useCheckoutState((state) => [state.toggleHorsePackagesTest])
  const horse = useCheckoutState((state) => state.orderHorses.find((horse) => horse.id === horseId))

  const testIsSelected = horse?.packages.find((p) => p.id === test.id)

  const onClickCard = () => {
    if (!horseId) return
    toggleTest(test)
  }

  return (
    <Card
      className={'flex flex-col justify-between h-full p-1 md:p-2 mt-2 md:mt-4 first:mt-0 cursor-pointer w-full'}
      onClick={onClickCard}
    >
      <h5 className="text-lg font-bold tracking-tight text-gray-900">{test.name}</h5>

      <p className={'mt-1 md:mt-2 font-normal text-gray-700 flex-grow'}>{test.description}</p>

      <div className={'mt-1 md:mt-2 flex items-center justify-between mr-2 mb-1'}>
        <span className="text-xl font-bold text-edxred-500 ">{currencyFormatter.format(test.price / 100)}</span>
        <Checkbox
          className="w-6 h-6 text-edxred-500 bg-gray-50 focus:ring-edxred-500/60 ml-4"
          checked={!!testIsSelected}
        />
      </div>
    </Card>
  )
}

export default TestCard
