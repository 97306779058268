import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Heading } from '../components/Heading'
import { ScreenLayout } from '../components/ScreenLayout'
import { Button } from '../components/Button'
import { FaCheck } from 'react-icons/fa'
import { Label } from '@edx/react-common'
import { useHorses } from '../hooks/useHorses'
import { Spinner } from 'flowbite-react'

const GettingStartedSteps = () => {
  const navigate = useNavigate()

  const { data, isLoading, isValidating } = useHorses()

  // if the user has horses, redirect to the horse selection page
  if (isLoading || isValidating) {

    return (
      <div className="mt-12 text-center flex flex-col items-center justify-center">
        <Spinner className="fill-edxred-500" size="lg" aria-label="Center-aligned spinner" />
      </div>
    )
  }

  if (data?.data.length) {
    navigate('/horse-selection')
  }

  return (
    <ScreenLayout>
      <Heading>Get Ready to Shop for Genetic Tests!</Heading>

      <div className="flex flex-col space-y-2 my-4">
        <div className="flex flex-col  space-y-1">
          <div className="flex items-center">
            <FaCheck size={20} />
            <Label className="ml-2 font-medium text-lg capitalize">Add a horse profile</Label>
          </div>
          <div className="ml-8">
            <p className="text-sm text-gray-500">Enter information about your horse(s) such as name, breed and age.</p>
          </div>
        </div>

        <div className="flex flex-col  space-y-1">
          <div className="flex items-center">
            <FaCheck size={20} />
            <Label className="ml-2 font-medium text-lg capitalize">
              Select genetic tests to purchase for your horse
            </Label>
          </div>
          <div className="ml-8">
            <p className="text-sm text-gray-500">
              Choose from a list of available genetic tests. Each test comes with a brief description explaining its
              purpose and benefits.
            </p>
          </div>
        </div>

        <div className="flex flex-col  space-y-1">
          <div className="flex items-center">
            <FaCheck size={20} />
            <Label className="ml-2 font-medium text-lg capitalize">Submit Your Order</Label>
          </div>
          <div className="ml-8">
            <p className="text-sm text-gray-500">
              Once you've selected the desired genetic tests, you can submit your order for processing. Then, you will
              receive steps to collect and submit your horse's DNA sample.
            </p>
          </div>
        </div>
      </div>

      <Button className="w-full" onClick={() => navigate('/add-horse?next_page=/horse-selection')}>
        Let's Get Started
      </Button>
    </ScreenLayout>
  )
}

export default GettingStartedSteps
