import { IOrderHorse } from '../context/form-context';
import { post } from '../api/base';
import TagManager from 'react-gtm-module';

export interface OrdersCreateDto {
	horses: IOrderHorse[];
  	paymentMethod: string;
	coupon?: { id: number; };
	transactionId: string;
	buyerToken: string;
};

export const useOrdersCreate = () => {
	console.log("Event: InitiateCheckout");
	TagManager.dataLayer({
		dataLayer: {
			event: 'InitiateCheckout',
		},
	});
	return (params: OrdersCreateDto) => post('/api/OrdersCreate', params);
};
