import useSWR from 'swr'
import { fetcher } from '../api/base'
import { EdxApiTypes } from '@edx/api'

export const usePackages = () => {
  return useSWR<{
    data: ({
      id: number
      name: string
      description: string
      groups: { id: number; name: string }[]
      isBundle: boolean
      price: number
      positionIndex: number
      priorityOrder: number
      tests: any[]
      reportTypes: { id: number; name: string }[]
    } & EdxApiTypes.OrderPackageResponse)[]
  }>(`/api/OrderPackages`, fetcher)
}
