import React, { useMemo, useState } from 'react'
import { Heading } from '../components/Heading'
import { ScreenLayout } from '../components/ScreenLayout'
import { HorsesComboBox } from '../components/HorsesComboBox'
import { useCheckoutState } from '../state/checkout'
import { Badge, cn, Input, Tabs, TabsList, TabsTrigger, useElementDimensions } from '@edx/react-common'
import { SubHeading } from '../components/SubHeading'
import { Spinner } from 'flowbite-react'
import { useAvailableTestsForHorse } from '../hooks/useAvailableTestsForHorse'
import { TestCardsList } from '../components/TestCardsList'
import { useNavigate } from 'react-router-dom'
import { FaCartShopping } from 'react-icons/fa6'

const TestSelectionMobile = () => {
  const navigate = useNavigate()
  const { currentHorse, orderHorses } = useCheckoutState()
  const { heightToBottomOfScreen, ref: scrollListRef } = useElementDimensions()
  const [tab, setTab] = useState('recommended')
  const [filterTests, setFilterTests] = useState('')

  const { allTests, addonTests, recommendedTests, loading: testsLoading } = useAvailableTestsForHorse(currentHorse)

  const showSearch = useMemo(() => {
    if (tab === 'all') return true

    return false
  }, [tab])

  const testList = useMemo(() => {
    let result: typeof allTests = []
    if (tab === 'all') result = allTests
    if (tab === 'recommended') result = recommendedTests
    if (tab === 'addons') result = addonTests

    if (filterTests) result = result.filter((test) => test.name.toLowerCase().includes(filterTests.toLowerCase()))

    return result
  }, [filterTests, tab, allTests, recommendedTests, addonTests])

  return (
    <ScreenLayout>
      <Heading>Select Horse</Heading>

      <HorsesComboBox
        buttonClassName="w-full"
        onSelectAddNewHorse={() => {
          navigate('/add-horse')
        }}
      />

      <div className="mt-2 mb-1">
        <Tabs defaultValue="recommended" onValueChange={setTab}>
          <TabsList
            className={cn('grid w-full', {
              'grid-cols-3': addonTests.length > 0,
              'grid-cols-2': !addonTests.length,
            })}
          >
            <TabsTrigger value="all">All Tests</TabsTrigger>

            <TabsTrigger value="recommended">Recommended</TabsTrigger>

            {addonTests.length > 0 && <TabsTrigger value="addons">Add-ons</TabsTrigger>}
          </TabsList>
        </Tabs>
      </div>

      {/*{tab === 'recommended' && (*/}
      {/*  <div className="mb-1">*/}
      {/*    <SubHeading>Tests we suggest for your horse</SubHeading>*/}
      {/*  </div>*/}
      {/*)}*/}

      {/*{tab === 'addons' && (*/}
      {/*  <div className="mb-1">*/}
      {/*    <SubHeading>Add-on tests we suggest in addition to the tests you have selected</SubHeading>*/}
      {/*  </div>*/}
      {/*)}*/}

      <div>
        {!currentHorse ? (
          <div className="text-center text-xl text-gray-400">Select a horse from the list</div>
        ) : (
          <div
            className="overflow-y-auto scrollbar pb-12"
            ref={scrollListRef as any}
            style={{ height: `${heightToBottomOfScreen - 5}px` }}
          >
            {showSearch && (
              <div>
                <Input
                  placeholder="Search tests..."
                  onChange={(e) => setFilterTests(e.currentTarget.value)}
                  value={filterTests}
                  type="search"
                />
              </div>
            )}

            <TestCardsList horse={currentHorse} tests={testList} isAllTests={tab==='all'} />

            {filterTests && testList.length === 0 && (
              <div className="text-center text-xl text-gray-400 my-8">No tests found</div>
            )}

            <>
              {testsLoading && (
                <div className="mt-12 text-center flex flex-col items-center justify-center">
                  <Spinner className="fill-edxred-500" size="lg" aria-label="Center-aligned spinner" />
                  <p className="whitespace-pre-wrap">Loading tests...</p>
                </div>
              )}
            </>
          </div>
        )}
      </div>
    </ScreenLayout>
  )
}

export default TestSelectionMobile
