import { ButtonHTMLAttributes, PropsWithChildren } from "react"
import { BASE_IMAGE_URL } from "../config/environment"
import horseIconSvg from '../assets/horse-icon.svg'

type AvatarProps = PropsWithChildren<{
  src?: string
  alt?: string
  className?: string
}> &
  ButtonHTMLAttributes<HTMLImageElement>

export default function Avatar({ src='', className='', alt='', ...avatarProps }: AvatarProps) {
  if (src.includes('horse/images')) {
    if (src.startsWith("/"))
      src = src.slice(1);
    src = `${BASE_IMAGE_URL}/${src}`;
    src = src.replace('.max.jpg', '.min.jpg');
  } else {
    src = horseIconSvg;
  }
  return (
    <img src={src} className={`${className} avatar-image`} alt={alt} {...avatarProps}/>
  )
}

Avatar.defaultProps = {}

export { Avatar }