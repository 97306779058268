import { post } from '../api/base';

export interface LoginDto {
	userName: string;
	password: string;
};

export const useLogin = () => {
	return (params: LoginDto) => post('/api/Authentication', params);
};
