import React, { PropsWithChildren } from 'react'

function DesktopRouteWrapper({ children }: PropsWithChildren) {
  return (
    <div className="bg-gray-200/70 py-4 h-full grid overflow-scroll">
      <div className='flex justify-center items-center'>
        <div className="bg-white rounded shadow inset p-6 min-w-[650px] max-w-[800px]">{children}</div>
      </div>
    </div>
  )
}

DesktopRouteWrapper.defaultProps = {}

export { DesktopRouteWrapper }
